.container-toggle {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: solid;
    margin-right: 10px;
    border-radius: 3px;
}

.toggle-langages__item {
    cursor: pointer;
    margin: 0% 10px;
}

.toggle-langages__item--active {
    color: #fff;
}