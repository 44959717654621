body {
  margin: 1%;
  padding: 0;
  font-family: Montserrat, cursive;
}

:root {
  --primary: #8A64FF;
  --secondary: #3399FF;
}

@font-face {
  font-family: 'LoveboxFont';
  src: local('LoveboxFont'), url(./fonts/RobotoMono-Lovebox.woff) format('woff');
}

@font-face {
  font-family: 'Montserrat';
  src: url(./fonts/Montserrat/Montserrat-Regular.ttf);
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url(./fonts/Montserrat/Montserrat-Bold.ttf);
}

h1, h2, h3 {
  color: #8A64FF;
}

h2 {
  margin-left: 10px;
  margin-right: 10px;
}

.default-image-input {
  visibility: hidden;
  height: 1px;
  width: 1px;
}

#message-input-1 {
  visibility: hidden;
  height: 1px;
  width: 1px;
}
#message-drop-1 {
  margin-top: 0px !important;
  cursor: pointer;
  transition: background-color 0.5s;
}
#message-drop-1:hover {
  background-color: #999;
  color: white;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-title {
  margin-bottom: 22px;
}

.message {
  font-family: LoveboxFont;
}

.discountButton {
  margin-left: 15px;
  margin-bottom: 15px;
}

nav {
  background-color: #8A64FF;
  border-radius: .3rem .3rem 0 0;
}

nav a {
  color: white;
}

nav a:hover {
  color: white;
  font-weight: bold;
}

.jumbotron {
  margin-top: 0px;
  background-color: #8A64FF15;
  border-radius: 0 0 .3rem .3rem;
  padding: 1em;
}

.nav-item {
  cursor: pointer;
}

.flex-row {
  flex-direction: row;
}

.progress {
  width: 100%;
}

.login-error-message {
  height: 2px;
  position: relative;
  left: 16px;
  top: -11px;
}

.btn-primary { background-color: #8A64FF; min-width: 100px; }
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:disabled {
  background-color: #8A74FF !important; min-width: 100px !important; border-color: #8A74FF !important;
}
.color-success { color: #33CC99 }
.color-warning { color: #FF713D }
.color-danger { color: #E53D00 }
.bold { font-weight: bold }

.text-primary { color: #8A64FF !important }
